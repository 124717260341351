import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ScrollUpButton from "react-scroll-up-button";

import Layout from "../components/layout";
import Seo from "../components/seo";

const landing = () => {
    return (
        <Layout pageInfo={{ pageName: "Terms" }}>
            <ScrollUpButton ContainerClassName="custom-scroll-button" />
            <Seo title="DeFi Powered rewards Generator" />
            <div className="py-140 mb-4  text-justified ">
                <Container>
                    <Row className="mb-2 justify-content-center">
                        <Col md={12} lg={9} className="mb-md-5 mb-4">
                            <div className="text-center px-md-4 mb-4">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.  Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.</p>
                            </div>

                            <div className="terms-area">
                                <h6>Terms & Conditions</h6>    

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.  Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.  Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.</p>

                                <ul>
                                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.  Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.</li>
                                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.  Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.</li>
                                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.  Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.</li>
                                </ul>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.  Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.  Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.</p>

                                <ul>
                                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.  Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.</li>
                                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.  Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.</li>
                                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.  Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.</li>
                                </ul>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.  Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.  Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.</p>

                                <ul>
                                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.  Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.</li>
                                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.  Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.</li>
                                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.  Imperdiet elit bibendum risus dui, mattis aliquam nascetur ultrices non.</li>
                                </ul>
                            </div>

                            <button type='button' className='text-white btn btn-primary mb-4 w-100 btn-lg'>CREATE ILO</button>

                            <div className="text-center px-md-4 mb-4">
                                <p>By clicking on “Create ILO” you hereby agree and comply with ShieldPad’s terms and conditions of service.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default landing;